import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import NavButtons from 'components/Advanced_User/INSTAR_MQTT_Broker/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "INSTAR MQTT Server with HiveMQ",
  "path": "/Advanced_User/INSTAR_MQTT_Broker/HiveMQ/",
  "dateChanged": "2019-12-27",
  "author": "Mike Polinowski",
  "excerpt": "MQTT (Message Queuing Telemetry Transport) is a machine-to-machine (M2M)/ Internet of Things (IoT) connectivity protocol. It was designed as an extremely lightweight publish/subscribe messaging transport. It is useful for connections with remote locations where a small code footprint is required and/or network bandwidth is at a premium. All Full HD cameras now offer an internal MQTT broker: IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9010 Full HD, IN-9020 Full HD.",
  "image": "../AU_SearchThumb_MQTT.png",
  "social": "/images/Search/AU_SearchThumb_MQTT.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_MQTT_white.webp",
  "chapter": "Advanced User",
  "category": "smarthome",
  "type": "HiveMQ"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='INSTAR MQTT Server with HiveMQ' dateChanged='2019-12-27' author='Mike Polinowski' tag='INSTAR IP Camera' description='FHEM is a GPL`d perl server for house automation. It is used to automate some common tasks in the household like switching lamps, shutters, heating. Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD' image='/images/Search/AU_SearchThumb_MQTT.png' twitter='/images/Search/AU_SearchThumb_MQTT.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/INSTAR_MQTT_Broker/HiveMQ/' locationFR='/fr/Advanced_User/INSTAR_MQTT_Broker/HiveMQ/' crumbLabel="MQTT" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "instar-mqtt-server-with-hivemq",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#instar-mqtt-server-with-hivemq",
        "aria-label": "instar mqtt server with hivemq permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`INSTAR MQTT Server with HiveMQ`}</h1>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#install-docker-on-linux"
        }}>{`Install Docker on Linux`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#enable-the-loop-module"
            }}>{`Enable the Loop Module`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#install-docker"
            }}>{`Install Docker`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#start-and-enable-docker"
            }}>{`Start and Enable docker`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#hivemq"
        }}>{`HiveMQ`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#download-and-run-hivemq"
            }}>{`Download and Run HiveMQ`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#connecting-your-camera"
            }}>{`Connecting your Camera`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#node-red"
        }}>{`Node-RED`}</a></li>
    </ul>
    {/* /TOC */}
    <h2 {...{
      "id": "install-docker-on-linux",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#install-docker-on-linux",
        "aria-label": "install docker on linux permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Install Docker on Linux`}</h2>
    <p>{`I am going to install Docker on a Arch Linux system. For other operating systems please check out the official `}<a parentName="p" {...{
        "href": "https://docs.docker.com"
      }}>{`Docker documentation`}</a>{`.`}</p>
    <h3 {...{
      "id": "enable-the-loop-module",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#enable-the-loop-module",
        "aria-label": "enable the loop module permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Enable the Loop Module`}</h3>
    <p>{`First check if the `}<a parentName="p" {...{
        "href": "http://man7.org/linux/man-pages/man4/loop.4.html"
      }}>{`Loop Module`}</a>{` is already loaded on your system:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`lsmod `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`grep`}</span>{` loop`}</code></pre></div>
    <p>{`If the Loop module has been loaded, you can skip the following steps. Otherwise run the following two commands:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`tee`}</span>{` /etc/modules-load.d/loop.conf `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<<<`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'loop'`}</span>{`
modprobe loop`}</code></pre></div>
    <p>{`Re-run `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`lsmod | grep loop`}</code>{` to verify that the loop module is now running.`}</p>
    <h3 {...{
      "id": "install-docker",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#install-docker",
        "aria-label": "install docker permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Install Docker`}</h3>
    <p>{`Now we can install docker from the `}<a parentName="p" {...{
        "href": "https://www.archlinux.org/packages/?name=docker"
      }}>{`community repository`}</a>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`pacman -S docker`}</code></pre></div>
    <p>{`Once Docker is installed continue with starting the service and enable it to run on system startup.`}</p>
    <h3 {...{
      "id": "start-and-enable-docker",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#start-and-enable-docker",
        "aria-label": "start and enable docker permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Start and Enable docker`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`systemctl start docker
sytemctl `}<span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`enable`}</span>{` docker
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` docker info`}</code></pre></div>
    <h2 {...{
      "id": "hivemq",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#hivemq",
        "aria-label": "hivemq permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`HiveMQ`}</h2>
    <p>{`I am going to run the trial version of the commercial `}<a parentName="p" {...{
        "href": "https://www.hivemq.com/downloads/docker/"
      }}>{`HiveMQ MQTT Broker`}</a>{` inside a docker container. Note that you could also use the free `}<a parentName="p" {...{
        "href": "https://www.hivemq.com/hivemq/editions/"
      }}>{`Community Edition`}</a>{` instead. The commercial trial version is `}<strong parentName="p">{`limited to 25 connected clients`}</strong>{`.`}</p>
    <h3 {...{
      "id": "download-and-run-hivemq",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#download-and-run-hivemq",
        "aria-label": "download and run hivemq permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Download and Run HiveMQ`}</h3>
    <p>{`To download the image from Docker Hub run the following command:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`docker run -p `}<span parentName="code" {...{
            "className": "token number"
          }}>{`8080`}</span>{`:8080 -p `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1883`}</span>{`:1883 hivemq/hivemq4`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/fd95d1d48567f3a3d4ad58eb45f480fd/32ac3/hiveMQ_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABQ0lEQVQoz31STXeCMBDE+FnF1p5a7ckeFQg+CIRQie3//0/Tt0uQWLSHebObzc5O9iXY5w32yuJTWeykwVZW+EgNdrK6xu+JxlussY1LLI8KwcHDseiZsFAW86zBE/MZs+zMvMgbBwuRGghpEMQaQVRCRCVGUTkUI9xMOyiIqOAG34FwIlQbCAwEvYKIS8xSw6B4HGtMZcX5VBpMkgp/DQxE/UNyQY3ztMZMGifexyR+I3BPUHg7ISZXBHIovLhlzfdG/+1wnGjMT/X1SeSCz9Kac37mvcZH6Fz4rtjBsejBjtoG4pHjwT4J5Cik73H6YldL+jKnmgd0DrtV0N3u+R0mib6ugxCQQKgs1sqycBtfOH/VP3guLngpv7HKG2bKCeuivdPViVdZg4AENq6BRZXlmJiFsv48zC02bkjoDPS1tucXjUA42Nom1p8AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fd95d1d48567f3a3d4ad58eb45f480fd/e4706/hiveMQ_02.avif 230w", "/en/static/fd95d1d48567f3a3d4ad58eb45f480fd/d1af7/hiveMQ_02.avif 460w", "/en/static/fd95d1d48567f3a3d4ad58eb45f480fd/7f308/hiveMQ_02.avif 920w", "/en/static/fd95d1d48567f3a3d4ad58eb45f480fd/92269/hiveMQ_02.avif 1249w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/fd95d1d48567f3a3d4ad58eb45f480fd/a0b58/hiveMQ_02.webp 230w", "/en/static/fd95d1d48567f3a3d4ad58eb45f480fd/bc10c/hiveMQ_02.webp 460w", "/en/static/fd95d1d48567f3a3d4ad58eb45f480fd/966d8/hiveMQ_02.webp 920w", "/en/static/fd95d1d48567f3a3d4ad58eb45f480fd/c7643/hiveMQ_02.webp 1249w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fd95d1d48567f3a3d4ad58eb45f480fd/81c8e/hiveMQ_02.png 230w", "/en/static/fd95d1d48567f3a3d4ad58eb45f480fd/08a84/hiveMQ_02.png 460w", "/en/static/fd95d1d48567f3a3d4ad58eb45f480fd/c0255/hiveMQ_02.png 920w", "/en/static/fd95d1d48567f3a3d4ad58eb45f480fd/32ac3/hiveMQ_02.png 1249w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/fd95d1d48567f3a3d4ad58eb45f480fd/c0255/hiveMQ_02.png",
              "alt": "INSTAR MQTT Server with HiveMQ",
              "title": "INSTAR MQTT Server with HiveMQ",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`So now we are able to access the HiveMQ MQTT service via `}<em parentName="p">{`localhost`}</em>{` on the exposed web port `}<strong parentName="p">{`8080`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0f46de3ec7c4bea3325f953825acf782/cd138/hiveMQ_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "54.347826086956516%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAABaklEQVQoz52ST0sCURTF/S6l5kBZq2oTGNImadUuMGxRmyKQZhRqJQgZzBCtgzJxQqWZgfRb+GdjG7+BmyBl5qmj98QbmhiiYPLC4bz3OPy4770bmEwm8Go8Hjs+Go1gWRYxxsAY404WY86Zm3Hdq8DPAzf4VeTx7/V0Ov0V9iuQB4kIvV4P1WoVmqaTob9A1zVomka1Wg39fh+z2cxfh/yKvMrlMoKLCxQRVqFcxd7PjzYHS0IU4XCIms2mk+FZ38BKpYJQMEgr0TWcnezbhwd7tiAsQxAi1Ol0/g80DAOpVAqiKNKFmIUoZpDJSJRMJtFut/0D+e/yajQakGWZVFVFqfRED49FUp9ryOXy1Gq1nAzP+gbyDiVJgqIoKBRucHd7jeK9jMvsKeZ6w9d6HfF4HInELmLbO0gfb6FU2EA+vY5u981/h97B5nDTNDEcDvAxGMI0GUyLYa7B5m7btiM+yFzu/i8YB34CrOkSlNKE8BgAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0f46de3ec7c4bea3325f953825acf782/e4706/hiveMQ_01.avif 230w", "/en/static/0f46de3ec7c4bea3325f953825acf782/d1af7/hiveMQ_01.avif 460w", "/en/static/0f46de3ec7c4bea3325f953825acf782/7f308/hiveMQ_01.avif 920w", "/en/static/0f46de3ec7c4bea3325f953825acf782/bffb7/hiveMQ_01.avif 1220w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0f46de3ec7c4bea3325f953825acf782/a0b58/hiveMQ_01.webp 230w", "/en/static/0f46de3ec7c4bea3325f953825acf782/bc10c/hiveMQ_01.webp 460w", "/en/static/0f46de3ec7c4bea3325f953825acf782/966d8/hiveMQ_01.webp 920w", "/en/static/0f46de3ec7c4bea3325f953825acf782/ea5cd/hiveMQ_01.webp 1220w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0f46de3ec7c4bea3325f953825acf782/81c8e/hiveMQ_01.png 230w", "/en/static/0f46de3ec7c4bea3325f953825acf782/08a84/hiveMQ_01.png 460w", "/en/static/0f46de3ec7c4bea3325f953825acf782/c0255/hiveMQ_01.png 920w", "/en/static/0f46de3ec7c4bea3325f953825acf782/cd138/hiveMQ_01.png 1220w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0f46de3ec7c4bea3325f953825acf782/c0255/hiveMQ_01.png",
              "alt": "INSTAR MQTT Server with HiveMQ",
              "title": "INSTAR MQTT Server with HiveMQ",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The default login to the HiveMQ Dashboard is username `}<strong parentName="p">{`admin`}</strong>{` and password `}<strong parentName="p">{`hivemq`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/01a23029cdf682f4832cc433f6eff5be/cd138/hiveMQ_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.91304347826087%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAABqElEQVQoz41T2W4TQRDcv7NkG/PgTTaW5SisIuBT+AAeeeTHsCXiIw54PefOUah6siYBHhiptDN9VFdPz1bt6m368ml8bN/d5vV6g8fHQ+66Dn3f/wXv/avvn3vnHKr37U36+vn+9PHDff7+8EBj1tpIgLVWEhhonbskDUT0DzYihIDqqq5xdb3E3d0tDod9jjHDuR45J0lIKcKT0BiklGSfYkSMUUhSLDYWCN6jumnm+c1klJvrObbbLStmpTS01tBGg/vQ94ghCFig9x7swhgrqoqv2KvFYoGmadC2rRAywBgj6ghjnCjjCiGJn20qpXA+n9H35Ux7ThFVXdcgVqsVdrudJOac8XLxTIQQJTE8KyWonsrE5xWq+bzGbDYTwv1+LwRUNJC8BFAKUdFmc8K3dYenp+e2UxJyUTgej/+LcFBOAucirKXioZvir6huNBphuVxeCDlBkv4Lg8LjUQu6zsg9c8pENZlMLoS8wxAjlCpBnCL3hNYWzvlLQWuDwHs+odIRC1bT6fSVQlbh9Pi3kIjvsRBYsSt1xo+fJ4RYpsrBxPgbvwDLgh5pu4HTjAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/01a23029cdf682f4832cc433f6eff5be/e4706/hiveMQ_03.avif 230w", "/en/static/01a23029cdf682f4832cc433f6eff5be/d1af7/hiveMQ_03.avif 460w", "/en/static/01a23029cdf682f4832cc433f6eff5be/7f308/hiveMQ_03.avif 920w", "/en/static/01a23029cdf682f4832cc433f6eff5be/bffb7/hiveMQ_03.avif 1220w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/01a23029cdf682f4832cc433f6eff5be/a0b58/hiveMQ_03.webp 230w", "/en/static/01a23029cdf682f4832cc433f6eff5be/bc10c/hiveMQ_03.webp 460w", "/en/static/01a23029cdf682f4832cc433f6eff5be/966d8/hiveMQ_03.webp 920w", "/en/static/01a23029cdf682f4832cc433f6eff5be/ea5cd/hiveMQ_03.webp 1220w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/01a23029cdf682f4832cc433f6eff5be/81c8e/hiveMQ_03.png 230w", "/en/static/01a23029cdf682f4832cc433f6eff5be/08a84/hiveMQ_03.png 460w", "/en/static/01a23029cdf682f4832cc433f6eff5be/c0255/hiveMQ_03.png 920w", "/en/static/01a23029cdf682f4832cc433f6eff5be/cd138/hiveMQ_03.png 1220w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/01a23029cdf682f4832cc433f6eff5be/c0255/hiveMQ_03.png",
              "alt": "INSTAR MQTT Server with HiveMQ",
              "title": "INSTAR MQTT Server with HiveMQ",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "connecting-your-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#connecting-your-camera",
        "aria-label": "connecting your camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Connecting your Camera`}</h3>
    <p>{`With the HiveMQ Broker online we are now able to connect our camera's MQTT service to it. Go to the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Network/MQTT/"
      }}>{`MQTT Menu`}</a>{`, activate the MQTT service and the use of an external MQTT Broker. Type in the IP address of the machine you installed HiveMQ on, leave the default MQTT ports as in the screenshot below and deactivate the user authentication:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0287d3145eca3547194fe53d529a6de7/8de58/hiveMQ_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAACUUlEQVQoz22Sy27TQBSG8xZUos3FThTHThrSuHZSq3Uu9T2xM74kjWnVChYNvSChhouKxIpNV2zZsCwLFjwDz8FDsGSD6h/NVEUVsPh1ZhbzzXdmTq7RaECSJNTrddSEKqRmG/7FJ4zefIG1vIa9/AzrXsyLa8TvvsI+vspW19YyWZahquqPfD7/vVqt/syJogiamihCqFYh1RuIDhY4WLxEcnSO6PAMMc3RGdvHhyfYe/YW7t4i29hoZYqiotVq3UiS9Ivn+ZsctbuDCoIASRQRjF3MkhBJNEESTkCCEXzPxti14dm7GPsEzihAR1WoHbPs9XrQdR3MkEJrtdotUJJACME8TTGdzhBFMUgYot/vQ1FVUKPNzU0M+n3Ispy1221ompYZhpFZlpXlKIi1fA84mUwwn88RxzGDh2EI3/fheR5czwMhIUajMQVCURRsb2+zC7vdLv4LpJA0TRnoDug6DkzTwO6gD8cy4LoOM+10Otja2mJ1fX39Fvh3y0EQYDqdIooilpAQDE0Xmj7EwCEw/RSmM0ZP15mZpmnMtNls/t+QAmezGZIkQRSFCCYE7xcePp4PsP/qA4Krb/Aen6KryhBFCaVSCTzPs/PsU+gM/vnle4b0DZlhGOH56QleL1/gbP8plukx9kiMRxstrDxYQaFQgGEYzPIfQ7oeDoewbRuWZbFqWyZ2p6foHVziieHhUt9BsLODiiCgWCiA4zg6i+xsTpTqbJhppaFQql8ul1ml4TgefHENfOEh8hyH1XIZRY5DqVRkMBqeL6NSqeA3jmV+cllQh9AAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0287d3145eca3547194fe53d529a6de7/e4706/hiveMQ_04.avif 230w", "/en/static/0287d3145eca3547194fe53d529a6de7/d1af7/hiveMQ_04.avif 460w", "/en/static/0287d3145eca3547194fe53d529a6de7/7f308/hiveMQ_04.avif 920w", "/en/static/0287d3145eca3547194fe53d529a6de7/8ce75/hiveMQ_04.avif 1219w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0287d3145eca3547194fe53d529a6de7/a0b58/hiveMQ_04.webp 230w", "/en/static/0287d3145eca3547194fe53d529a6de7/bc10c/hiveMQ_04.webp 460w", "/en/static/0287d3145eca3547194fe53d529a6de7/966d8/hiveMQ_04.webp 920w", "/en/static/0287d3145eca3547194fe53d529a6de7/4fba2/hiveMQ_04.webp 1219w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0287d3145eca3547194fe53d529a6de7/81c8e/hiveMQ_04.png 230w", "/en/static/0287d3145eca3547194fe53d529a6de7/08a84/hiveMQ_04.png 460w", "/en/static/0287d3145eca3547194fe53d529a6de7/c0255/hiveMQ_04.png 920w", "/en/static/0287d3145eca3547194fe53d529a6de7/8de58/hiveMQ_04.png 1219w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0287d3145eca3547194fe53d529a6de7/c0255/hiveMQ_04.png",
              "alt": "INSTAR MQTT Server with HiveMQ",
              "title": "INSTAR MQTT Server with HiveMQ",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Going back to the HiveMQ Dashboard you should now see that you have connected devices:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/94dbc379111215c61a975b3efa69b0a5/2ba99/hiveMQ_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "54.78260869565218%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABzElEQVQoz3VTbW7aQBD16fjDR6XYQIhAlAgJztEb9Ag9Q2/QniNR2qg/SGzs2uv9nPWrZgwRNMpaTzvemX375q2drBaf8PVLivvNZ/x6fkZZltBaw3v/IZxz72KerTVI9ttbfP+2w36/w5/HR6i6hpWkhTFGCnnmtT628D685ZiMY565JplmKabTO2x3W7z++Al9KGBDJwXaGMQYYU6KiQitqtFFEuKm1qBAQhiJoJoayXx2g/FwgNt5hrwo4AOhbTWss9BGw1kHCgEhBBAFkHfwzsI5j1ZZIYwUJe+55cXiDmmWYbO5x+FwkNPatoVSLVSjoI1Fx0/XyWG8kT3O8xzV3/rknRVwnGRZhtlshvV6jSLPwYM3Xw5+ZzgfLi7HyRpRFFvOSNI0xWQywWq1QlEUQsAJLo4nIuCMPsete1MiOIX/RzKfzzEcDkXh8Xi8IjyTsQrvCTF2kgvBw7ZHeKuuOmAk4/EYg8EAy+XyTSHfJpMwWX7UeHgq8fS7gnOErusvQED0XiGrOxOywtgBWrPJHsY6lKVCVbWoG9PbcPLqrOjSP/FwNBoJIXv4+vKCpmlQVSWKohTiXpFH09Soqkr+pLKqpYv+U6Ir/ANplB5y7uRgigAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/94dbc379111215c61a975b3efa69b0a5/e4706/hiveMQ_05.avif 230w", "/en/static/94dbc379111215c61a975b3efa69b0a5/d1af7/hiveMQ_05.avif 460w", "/en/static/94dbc379111215c61a975b3efa69b0a5/7f308/hiveMQ_05.avif 920w", "/en/static/94dbc379111215c61a975b3efa69b0a5/eac99/hiveMQ_05.avif 1221w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/94dbc379111215c61a975b3efa69b0a5/a0b58/hiveMQ_05.webp 230w", "/en/static/94dbc379111215c61a975b3efa69b0a5/bc10c/hiveMQ_05.webp 460w", "/en/static/94dbc379111215c61a975b3efa69b0a5/966d8/hiveMQ_05.webp 920w", "/en/static/94dbc379111215c61a975b3efa69b0a5/0fff5/hiveMQ_05.webp 1221w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/94dbc379111215c61a975b3efa69b0a5/81c8e/hiveMQ_05.png 230w", "/en/static/94dbc379111215c61a975b3efa69b0a5/08a84/hiveMQ_05.png 460w", "/en/static/94dbc379111215c61a975b3efa69b0a5/c0255/hiveMQ_05.png 920w", "/en/static/94dbc379111215c61a975b3efa69b0a5/2ba99/hiveMQ_05.png 1221w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/94dbc379111215c61a975b3efa69b0a5/c0255/hiveMQ_05.png",
              "alt": "INSTAR MQTT Server with HiveMQ",
              "title": "INSTAR MQTT Server with HiveMQ",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Switching to the `}<strong parentName="p">{`Clients`}</strong>{` tab will now show all your connected devices. Every camera will use a `}<strong parentName="p">{`Client ID`}</strong>{` starting with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`mosq/`}</code>{` for the internal Mosquitto Broker.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ed1bdf4f5d7727751d4150a5d5fc0ab6/2ba99/hiveMQ_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "34.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAAsTAAALEwEAmpwYAAABOUlEQVQoz52MW07DMBBFszh+QFQVIgktES0NjyCxBHbAFtgEu4Ef+kApasFuGjeJndiei+JIRXzCSEf3zGhmvHHUt48PxyKeXNJ8voCUkuq6xn9QUsK7vw3N81PEk+QaabokrbV72DTNn9BaO7yzQYRodIMkuSPOOcgSlFKQUrlsKQsJrQ2stSAihzFm72QthBAQuYAXBid0dHhAw0EAxhhpo0nKCrtih7IqoWqJSlbgGw7GGVStIJWE2Im9t5ltM3fjnQ9D+Kd9XMVjfPI10mqKt/wFH80Ma/uOlVk42j5V032/sl0u6xlm4tV5u+8FQYgwDBFPYjD+hcLk2NYchc2dF2bbYXNkimGrGITOUNocJYluX3GX7czzfR+9Xg+jixH4hqEt3RiQIZC2MC2NAQgdFj9O+F0EfAO6IPk4uajMNQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ed1bdf4f5d7727751d4150a5d5fc0ab6/e4706/hiveMQ_06.avif 230w", "/en/static/ed1bdf4f5d7727751d4150a5d5fc0ab6/d1af7/hiveMQ_06.avif 460w", "/en/static/ed1bdf4f5d7727751d4150a5d5fc0ab6/7f308/hiveMQ_06.avif 920w", "/en/static/ed1bdf4f5d7727751d4150a5d5fc0ab6/eac99/hiveMQ_06.avif 1221w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ed1bdf4f5d7727751d4150a5d5fc0ab6/a0b58/hiveMQ_06.webp 230w", "/en/static/ed1bdf4f5d7727751d4150a5d5fc0ab6/bc10c/hiveMQ_06.webp 460w", "/en/static/ed1bdf4f5d7727751d4150a5d5fc0ab6/966d8/hiveMQ_06.webp 920w", "/en/static/ed1bdf4f5d7727751d4150a5d5fc0ab6/0fff5/hiveMQ_06.webp 1221w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ed1bdf4f5d7727751d4150a5d5fc0ab6/81c8e/hiveMQ_06.png 230w", "/en/static/ed1bdf4f5d7727751d4150a5d5fc0ab6/08a84/hiveMQ_06.png 460w", "/en/static/ed1bdf4f5d7727751d4150a5d5fc0ab6/c0255/hiveMQ_06.png 920w", "/en/static/ed1bdf4f5d7727751d4150a5d5fc0ab6/2ba99/hiveMQ_06.png 1221w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ed1bdf4f5d7727751d4150a5d5fc0ab6/c0255/hiveMQ_06.png",
              "alt": "INSTAR MQTT Server with HiveMQ",
              "title": "INSTAR MQTT Server with HiveMQ",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "node-red",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#node-red",
        "aria-label": "node red permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Node-RED`}</h2>
    <p>{`You can now `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/#software"
      }}>{`connect every compatible MQTT Software`}</a>{` to the HiveMQ Broker to control every connected camera. In the following we will show how to connect `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/Node-RED/"
      }}>{`Node-RED`}</a>{` as an example.`}</p>
    <p>{`You can download the Node-RED flow that I am going to use from here: `}<a parentName="p" {...{
        "href": "/en/Node-RED_Flows/MQTT_HiveMQ_Node-RED.json"
      }}>{`Download Flow`}</a>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2b80d1f498373d53b10ccb0ff992872e/d326d/hiveMQ_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "42.608695652173914%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsTAAALEwEAmpwYAAAByklEQVQoz4WO3Y6acBTEfbYmTegb9Lr3Td/AC1+lN036AHW1rprUbRdZwXWDyJeAIMrHigIi+PdMs1y0aZraOZlkMsn5ZRp3o28fbNt+e9gfXltLi3Nsh/NcjztkGbeNIs40TM5beZxlLjld07n1es15nsf5vv8mTdNX3W73XavVkprN5mwwGL5vFEVxOZ1OSPYJwihEXhxRnSvUYgxZnoERQ1EWyI85jscj8jzHy0+WZXVmjJ2rqmJlWaIxuJ/C3Wxp4/hkyRY5ikOhE9AyikmNYoq3O3IXK1qpLkVuRHg5/OVfany8+0Tebk3m05L4Nk9CRyCVV2goK9TRdJIllfgv9zTuCKTcK8TOrIb8Sw2x/RlJsIYuW+BveAhfH6AJKkbzBXq6ifmjhnHdC9AmOi7sUi8h+mPY74Xt2x/YBDGiMIZv+Yi8CPsgwTIMsUr2CIIYG2tT98/+M9iZXQc+LRY4ZClsxcakN4HYF6GOFXw3TPRNC4ZsQboVIQ0lTAePSHfpdWAcRSjLE7SZAaEjYNITofIKRrKCnmFiMTMw6T5A6ku1/wtMkgRlVcLUbMzHcxhTA57qQrQsSJ4P03CgjOcwZ2btIiuuAn8C+U2OwwDcStkAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2b80d1f498373d53b10ccb0ff992872e/e4706/hiveMQ_07.avif 230w", "/en/static/2b80d1f498373d53b10ccb0ff992872e/d1af7/hiveMQ_07.avif 460w", "/en/static/2b80d1f498373d53b10ccb0ff992872e/7f308/hiveMQ_07.avif 920w", "/en/static/2b80d1f498373d53b10ccb0ff992872e/e22f7/hiveMQ_07.avif 1197w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2b80d1f498373d53b10ccb0ff992872e/a0b58/hiveMQ_07.webp 230w", "/en/static/2b80d1f498373d53b10ccb0ff992872e/bc10c/hiveMQ_07.webp 460w", "/en/static/2b80d1f498373d53b10ccb0ff992872e/966d8/hiveMQ_07.webp 920w", "/en/static/2b80d1f498373d53b10ccb0ff992872e/760a1/hiveMQ_07.webp 1197w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2b80d1f498373d53b10ccb0ff992872e/81c8e/hiveMQ_07.png 230w", "/en/static/2b80d1f498373d53b10ccb0ff992872e/08a84/hiveMQ_07.png 460w", "/en/static/2b80d1f498373d53b10ccb0ff992872e/c0255/hiveMQ_07.png 920w", "/en/static/2b80d1f498373d53b10ccb0ff992872e/d326d/hiveMQ_07.png 1197w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2b80d1f498373d53b10ccb0ff992872e/c0255/hiveMQ_07.png",
              "alt": "INSTAR MQTT Server with HiveMQ",
              "title": "INSTAR MQTT Server with HiveMQ",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`This flow controls the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Alarm/Areas/"
      }}>{`Motion Detection Areas`}</a>{` of two cameras - one with the IP address `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`192.168.2.73`}</code>{` & MQTT ID `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`091419200118`}</code>{` and one with the address `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`192.168.2.165`}</code>{` & MQTT ID `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`000389888811`}</code>{` (If you downloaded this flow you can use search and replace function of your text editor to swap these IP addresses to match your own camera setup).`}</p>
    <p>{`Each sequence starts with an MQTT Input node that receives updates for the corresponding `}<strong parentName="p">{`STATUS Topic`}</strong>{` for areas 1-4:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c0ee48e6d242ae3b9d839628bc61d019/cd138/hiveMQ_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "41.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsTAAALEwEAmpwYAAABwElEQVQoz22QS2/aUBCF/f83NY1C6Q6FSpTGJGADfuMHDxMItAGDomRZ1D2hvA1fdW+VrjrS0ZVmNN89c5RP+TyfCwXy+Ty5XI4Pqoqay/Hx6orr62tUVZV98RYKBYrFIqVSiUqlQrlcpqppmJZF7Vbj600J5WE4YvDwyHg84ftkzGT8SDqdMk9T0vmc2WxGOkt5epqyXC7ZbrccDgculwvn85nslHEBfoVdfhoWim6bfLmvEkQhoelgajWcOx3f8dA8HzeIsOsWhmawmM7Z7rbs93tOpxNZlv2FZhm/NxspxfYMNP2Gbj/GbfroFYOW1sKxPMq2g+kHNG4b1Mt10h+pXD4ej6xWK3a7nXQsJD4RM6VtezSqNXrdHnHUIXADOu2YTtzFimPiXl/2Aifg5flFnpudThImJEDvjoUUL4zQLYdekhD7MbZh4zZcgiCiHoa04y5+y8OsmyxmCwk8Hg6s12vWb28SJkpkKmZKkvTpdToMh0OcpktTa2Ldm7i2T8VxsIMI866F8c2QQLEonGy2G3nqe45CIgolSRIGgwHD0Yi2E2DrNl7Dpe2H1NoBftSRjls1k9fn139u/lfi5D9xxSPc5PyaGAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c0ee48e6d242ae3b9d839628bc61d019/e4706/hiveMQ_08.avif 230w", "/en/static/c0ee48e6d242ae3b9d839628bc61d019/d1af7/hiveMQ_08.avif 460w", "/en/static/c0ee48e6d242ae3b9d839628bc61d019/7f308/hiveMQ_08.avif 920w", "/en/static/c0ee48e6d242ae3b9d839628bc61d019/bffb7/hiveMQ_08.avif 1220w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c0ee48e6d242ae3b9d839628bc61d019/a0b58/hiveMQ_08.webp 230w", "/en/static/c0ee48e6d242ae3b9d839628bc61d019/bc10c/hiveMQ_08.webp 460w", "/en/static/c0ee48e6d242ae3b9d839628bc61d019/966d8/hiveMQ_08.webp 920w", "/en/static/c0ee48e6d242ae3b9d839628bc61d019/ea5cd/hiveMQ_08.webp 1220w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c0ee48e6d242ae3b9d839628bc61d019/81c8e/hiveMQ_08.png 230w", "/en/static/c0ee48e6d242ae3b9d839628bc61d019/08a84/hiveMQ_08.png 460w", "/en/static/c0ee48e6d242ae3b9d839628bc61d019/c0255/hiveMQ_08.png 920w", "/en/static/c0ee48e6d242ae3b9d839628bc61d019/cd138/hiveMQ_08.png 1220w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c0ee48e6d242ae3b9d839628bc61d019/c0255/hiveMQ_08.png",
              "alt": "INSTAR MQTT Server with HiveMQ",
              "title": "INSTAR MQTT Server with HiveMQ",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You will have add your new HiveMQ Broker by clicking on the pencil symbol next to the `}<strong parentName="p">{`Server`}</strong>{` dropdown menu:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/08842e687246092a89c69cbcb11fa44a/8de58/hiveMQ_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40.869565217391305%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsTAAALEwEAmpwYAAABtUlEQVQoz02RWW/aQBSF/f/fACFo+oBkkCqWlODigldss5g4IZUo/IG2ScMTiMXYZvmqGYkqVzqaqxnpu+fcUT6Vy3y+u6NcLlMoFMjl8+QLBYrFIqVSiVwuh6qqNBoNqtUqlUoFVa3SbDblXbvdxjBNOvUGjVoN5TF6Yvr4zGw248fLjJfZM4v5nOViwWK5ZD7/yXq9JkkSzuez1OVy4Xq9Sske+PWg8/u7iaIZPWrtFgPfw+sZ9FoPmF81HMum5TgYzoC3P68ck4Q0TcmyTEI+woXSLGN/2KMYtsZ9R2U8CbB0G62uobd0zL7NF8NEt11Wb++kWSpdCmAcH2QvBsRxLCXuhRTXtOned5iMxgT+EM/yGLpDhsMxRhDgj8asViuOx6MECNBut/sP2mw27Pd7+Sak2J6H1jeYhCGB7WN8M7C6Jq7r0/F9bM+XkeNjzHa7Zb3ZcDqdECV2KGKLU5QYoEzDkPFoRBRFmLpFt6XTb/ewDIe6adJzBvx9fSNJE+lExk5T6ViAbx8jSkYOw5BwOiWKnnDNgXRo6xau69EZDLC9gNX7isPhIB1+3NcNdHMoBvwD7MkwACnAIE4AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/08842e687246092a89c69cbcb11fa44a/e4706/hiveMQ_09.avif 230w", "/en/static/08842e687246092a89c69cbcb11fa44a/d1af7/hiveMQ_09.avif 460w", "/en/static/08842e687246092a89c69cbcb11fa44a/7f308/hiveMQ_09.avif 920w", "/en/static/08842e687246092a89c69cbcb11fa44a/8ce75/hiveMQ_09.avif 1219w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/08842e687246092a89c69cbcb11fa44a/a0b58/hiveMQ_09.webp 230w", "/en/static/08842e687246092a89c69cbcb11fa44a/bc10c/hiveMQ_09.webp 460w", "/en/static/08842e687246092a89c69cbcb11fa44a/966d8/hiveMQ_09.webp 920w", "/en/static/08842e687246092a89c69cbcb11fa44a/4fba2/hiveMQ_09.webp 1219w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/08842e687246092a89c69cbcb11fa44a/81c8e/hiveMQ_09.png 230w", "/en/static/08842e687246092a89c69cbcb11fa44a/08a84/hiveMQ_09.png 460w", "/en/static/08842e687246092a89c69cbcb11fa44a/c0255/hiveMQ_09.png 920w", "/en/static/08842e687246092a89c69cbcb11fa44a/8de58/hiveMQ_09.png 1219w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/08842e687246092a89c69cbcb11fa44a/c0255/hiveMQ_09.png",
              "alt": "INSTAR MQTT Server with HiveMQ",
              "title": "INSTAR MQTT Server with HiveMQ",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`And type in your Broker IP address and MQTT port as shown above.`}</p>
    <p>{`In the middle of each sequence we have an UI switch that emits a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`{"val":"0"}`}</code>{` when the switch is pushed into the `}<strong parentName="p">{`Off`}</strong>{` position. And a `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`{"val":"1"}`}</code>{` for the `}<strong parentName="p">{`On`}</strong>{` position:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3f36e556ccda45b9afbee19d317dffc0/cd138/hiveMQ_11.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAACQUlEQVQoz1WSTU/iUBSG+f8LGMOgs3HBJEgURUDoJ20phVJhaFUYYia6w9mpgIAgH8/kXsRkTvKmvYv7nPe898QOU9/5cXREKpUikUgQj8fl9+DggGQyuTt/SxBPxDk8OuT4+Jh0Ok02myWTyZDL5VAUhfzZGSfpNLHr9i+Eut2QKIoIw5B+r8/v3zv1ej36/T53t3c8PT2xWCz4+PhgX5v1mi3w1/EYKgaxkq6SKVzguC62ZqNcKmgFDcu0yJsqVdtBL+qUL8oMegOm0ynv7+/M53Op1WolG7y9vTGZTIhp1RLnpZ80/DpmuUrptEQlV8HQqpzoBqrlUD67opAt0L/ps1rvAAIs3K7XaylREmjrJpV8Eb/RpF7zqJk1PNvD8xpo9Tr1po9jOjiGw8Ofhy9H+7E3m42UqNFoRKzq1ChpOn6rRd1y0a8MzLKJ47gUay52vYGlVFGLmhx5uVx+Opx9uds7lMCW79NseLTbbYyKQeW8gnapYuoWp7qB7rgoeYWr3BWDuwGL5ZL5dMpkPGY2mzGbTr/c7oCtgCC4pt3uYOk2ekmXDm2rxqVtY7keZtlALajcD+4lROQnYZ8SWYoaj8fEAlfFN3O0gwbdKJKrc3N7Qyj+w89VikK6YZfhcCgvj8Yj+cJi1O12+3+GnntKVU8StByi6FbuoVAU7uCyQRTR6XTkHgqIuCgk8hRn8VACLJrEGl6Zmp3B92uI8X2/ie/7BEEgc91LnB8fH+VYYudeXl54fn6Wen19lTARxT9InfjGtgwH1AAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3f36e556ccda45b9afbee19d317dffc0/e4706/hiveMQ_11.avif 230w", "/en/static/3f36e556ccda45b9afbee19d317dffc0/d1af7/hiveMQ_11.avif 460w", "/en/static/3f36e556ccda45b9afbee19d317dffc0/7f308/hiveMQ_11.avif 920w", "/en/static/3f36e556ccda45b9afbee19d317dffc0/bffb7/hiveMQ_11.avif 1220w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3f36e556ccda45b9afbee19d317dffc0/a0b58/hiveMQ_11.webp 230w", "/en/static/3f36e556ccda45b9afbee19d317dffc0/bc10c/hiveMQ_11.webp 460w", "/en/static/3f36e556ccda45b9afbee19d317dffc0/966d8/hiveMQ_11.webp 920w", "/en/static/3f36e556ccda45b9afbee19d317dffc0/ea5cd/hiveMQ_11.webp 1220w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3f36e556ccda45b9afbee19d317dffc0/81c8e/hiveMQ_11.png 230w", "/en/static/3f36e556ccda45b9afbee19d317dffc0/08a84/hiveMQ_11.png 460w", "/en/static/3f36e556ccda45b9afbee19d317dffc0/c0255/hiveMQ_11.png 920w", "/en/static/3f36e556ccda45b9afbee19d317dffc0/cd138/hiveMQ_11.png 1220w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3f36e556ccda45b9afbee19d317dffc0/c0255/hiveMQ_11.png",
              "alt": "INSTAR MQTT Server with HiveMQ",
              "title": "INSTAR MQTT Server with HiveMQ",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The exit of each sequence is a MQTT output node that publishes the payload emitted by our switch to the corresponding `}<strong parentName="p">{`COMMAND Topic`}</strong>{` for area 1-4:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e13b1ad7542f1563fcbe28c8cac02467/636d3/hiveMQ_10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "41.30434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB3klEQVQozz2R207bQBBA/f8iNCWieSlFKkVtikuC7SS+23GuDbeWgFRacqHiAdoXImwncWxO5a3ESkezM9KemdVI26USb7a3KZVKbG5uslHYELFYLLK19ZpCoSDyPJbLZXZ2dni3u8v+/j57e3tUKhVUVaUmy5ReFZGGwxO+Dk84PT3j/Pycs9MzRqMRV1dXgvx+Obrg4vs3Hu7viaKI1WpFlmWCdL0mA/4GPXrlt0iqqfOh+gW35eMZLo1qA72mY9sesuNgeT5WTaVxUGX664Y4joVwvV6TpqkgSRKWyyU/rq+RDEdFVt7T6QVYdRulolCX65i6zUfDoOl4aAcaR5+OuPk5FoL88Xw+Z7FYCPIm+ZnNbpFc00aTq3Q7HQK/jWd6BE5AO+igBwGtbg/PdEX9dnrLKknEdHG84Okp5PFxThhFQjiZzpAcv4Wim3T7fSEyNRO7buF5LRTfxw06OA0HXdGZ3ExI1kuSZEEYzomiJ9I0//7qv3AyRur3e3TbbYbDIWbdQpM1modNLMOmYpoYXovmYQP1s8p0PCYM7wnDB+L4zwth9ECWPTKZXOfCAYPBgOPjY1zTw1AMbM3CdXxqrofTamNpJs1ak7vfd2KS5+d8r88vZFkq6tPZjH+yjyCnVjcAjQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e13b1ad7542f1563fcbe28c8cac02467/e4706/hiveMQ_10.avif 230w", "/en/static/e13b1ad7542f1563fcbe28c8cac02467/d1af7/hiveMQ_10.avif 460w", "/en/static/e13b1ad7542f1563fcbe28c8cac02467/7f308/hiveMQ_10.avif 920w", "/en/static/e13b1ad7542f1563fcbe28c8cac02467/ea3f7/hiveMQ_10.avif 1222w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e13b1ad7542f1563fcbe28c8cac02467/a0b58/hiveMQ_10.webp 230w", "/en/static/e13b1ad7542f1563fcbe28c8cac02467/bc10c/hiveMQ_10.webp 460w", "/en/static/e13b1ad7542f1563fcbe28c8cac02467/966d8/hiveMQ_10.webp 920w", "/en/static/e13b1ad7542f1563fcbe28c8cac02467/41bb6/hiveMQ_10.webp 1222w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e13b1ad7542f1563fcbe28c8cac02467/81c8e/hiveMQ_10.png 230w", "/en/static/e13b1ad7542f1563fcbe28c8cac02467/08a84/hiveMQ_10.png 460w", "/en/static/e13b1ad7542f1563fcbe28c8cac02467/c0255/hiveMQ_10.png 920w", "/en/static/e13b1ad7542f1563fcbe28c8cac02467/636d3/hiveMQ_10.png 1222w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e13b1ad7542f1563fcbe28c8cac02467/c0255/hiveMQ_10.png",
              "alt": "INSTAR MQTT Server with HiveMQ",
              "title": "INSTAR MQTT Server with HiveMQ",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`When you now switch to the MQTT Dashboard you will see a new tab called `}<strong parentName="p">{`HiveMQ`}</strong>{` with 8 switches to activate / deactivate all for alarm areas on your two cameras:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/579b4ca6010c742a72968c2e24daeb38/cd138/hiveMQ_12.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "28.695652173913043%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsTAAALEwEAmpwYAAABKklEQVQY03WMTU/CQBRFm7hRAnZmiAR2IIkyFSvtTCkzU+PHXhZujPGvgDt/s3V1TItEjXFx8nLPy71R/SaoXw+oNxH1NuJ9s+Nju6Nx9ZerNz/cP0RmqrCTmHzcw531eb7TPN8lrMOMddCswzmP1zNe7hOebjUPja80a39GcRpjxseYyTdRpyfoHEsOuwJ5MmLpAou84DKzJIuCm3KBXy0xS89VZpkvLJlpfEo3Vhz1JJ0fREoKlJJIETMaDQneY0yONTl5llFVAe8dq7Jss8kzymVBCJ6+krR9ub+CSEpJQxzHDIdDQggURYG1FmMMt5WjCg7nXJuNsZRlyU1YtT0hBPuNhl+Dg8GANE3RWpNozfkswaVTTKq5mF+i9az9zRONTycopf4MfgLwYsGdBnBjwgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/579b4ca6010c742a72968c2e24daeb38/e4706/hiveMQ_12.avif 230w", "/en/static/579b4ca6010c742a72968c2e24daeb38/d1af7/hiveMQ_12.avif 460w", "/en/static/579b4ca6010c742a72968c2e24daeb38/7f308/hiveMQ_12.avif 920w", "/en/static/579b4ca6010c742a72968c2e24daeb38/bffb7/hiveMQ_12.avif 1220w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/579b4ca6010c742a72968c2e24daeb38/a0b58/hiveMQ_12.webp 230w", "/en/static/579b4ca6010c742a72968c2e24daeb38/bc10c/hiveMQ_12.webp 460w", "/en/static/579b4ca6010c742a72968c2e24daeb38/966d8/hiveMQ_12.webp 920w", "/en/static/579b4ca6010c742a72968c2e24daeb38/ea5cd/hiveMQ_12.webp 1220w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/579b4ca6010c742a72968c2e24daeb38/81c8e/hiveMQ_12.png 230w", "/en/static/579b4ca6010c742a72968c2e24daeb38/08a84/hiveMQ_12.png 460w", "/en/static/579b4ca6010c742a72968c2e24daeb38/c0255/hiveMQ_12.png 920w", "/en/static/579b4ca6010c742a72968c2e24daeb38/cd138/hiveMQ_12.png 1220w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/579b4ca6010c742a72968c2e24daeb38/c0255/hiveMQ_12.png",
              "alt": "INSTAR MQTT Server with HiveMQ",
              "title": "INSTAR MQTT Server with HiveMQ",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      